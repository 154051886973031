export type Intent =
  | 'buildAPage'
  | 'replaceYourWebsite'
  | 'hostAnEvent'
  | 'sendEmailCampaigns'
  | 'sendTextMessages'
  | 'collectSignups';

export type IntentLabel = {
  key: Intent;
  label: string;
};

export type IntentInput = {
  intent: Intent;
  customIntent: string;
};

export const INTENTS: IntentLabel[] = [
  { key: 'buildAPage', label: 'Build a page' },
  { key: 'hostAnEvent', label: 'Host an event' },
  { key: 'sendEmailCampaigns', label: 'Send email campaigns' },
  { key: 'sendTextMessages', label: 'Send text messages' },
  { key: 'collectSignups', label: 'Collect signups' },
  { key: 'replaceYourWebsite', label: 'Replace your website' }
];
