export type Category =
  | 'travel'
  | 'tech'
  | 'music'
  | 'art'
  | 'entertainment'
  | 'fashion'
  | 'beauty'
  | 'design'
  | 'wellness'
  | 'fitness'
  | 'business'
  | 'marketing'
  | 'personal'
  | 'lifestyle'
  | 'productivity'
  | 'consulting'
  | 'coaching'
  | 'comedy'
  | 'nonProfit'
  | 'other';

export type CategoryLabel = {
  key: Category;
  label: string;
};

export type CategoryInput = {
  category: Category;
  customCategory: string;
};

export const CATEGORIES: CategoryLabel[] = [
  { key: 'art', label: 'Art' },
  { key: 'beauty', label: 'Beauty' },
  { key: 'business', label: 'Business' },
  { key: 'coaching', label: 'Coaching' },
  { key: 'comedy', label: 'Comedy' },
  { key: 'consulting', label: 'Consulting' },
  { key: 'design', label: 'Design' },
  { key: 'entertainment', label: 'Entertainment' },
  { key: 'fashion', label: 'Fashion' },
  { key: 'fitness', label: 'Fitness' },
  { key: 'lifestyle', label: 'Lifestyle' },
  { key: 'marketing', label: 'Marketing' },
  { key: 'music', label: 'Music' },
  { key: 'nonProfit', label: 'Non profit' },
  { key: 'personal', label: 'Personal' },
  { key: 'productivity', label: 'Productivity' },
  { key: 'tech', label: 'Tech' },
  { key: 'travel', label: 'Travel' },
  { key: 'wellness', label: 'Wellness' },
  { key: 'other', label: 'Other' }
];
