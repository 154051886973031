import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-logo',
  templateUrl: './norby-logo.component.html',
  styleUrls: ['./norby-logo.component.less']
})
export class NorbyLogoComponent {
  @Input() color: string = '#140936';
}
