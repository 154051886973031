import { APP_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

// 3rd party
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';

// App
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

// Libs
import {
  getSharedProviders,
  getAntProviders,
  DeviceService,
  ShareService,
  ContentInteractionsService,
  ApiService,
  AnalyticsService
} from 'shared';
import {
  MessageModule,
  MockDeviceService,
  MockShareService,
  MockContentInteractionsService,
  MockApiService,
  MockAnalyticsService
} from 'uikit';
import { CHAMELEON_API_KEY, CHAMELEON_FAST_URL, GTM_ID } from 'models';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MessageModule.forRoot(),

    NzDrawerModule,
    NzModalModule,
    RecaptchaV3Module
  ],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),

    // App
    ...getSharedProviders(environment),
    ...getAntProviders(environment),
    { provide: APP_ID, useValue: 'onboarding' },

    {
      provide: CHAMELEON_API_KEY,
      useValue: environment?.chameleonConfig?.apiKey
    },
    {
      provide: CHAMELEON_FAST_URL,
      useValue: environment?.chameleonConfig?.fastUrl
    },
    {
      provide: GTM_ID,
      useValue: environment?.gtmConfig?.id
    },
    {
      provide: MockDeviceService,
      useExisting: DeviceService
    },
    {
      provide: MockShareService,
      useExisting: ShareService
    },
    {
      provide: MockApiService,
      useExisting: ApiService
    },
    {
      provide: MockContentInteractionsService,
      useExisting: ContentInteractionsService
    },
    {
      provide: MockAnalyticsService,
      useExisting: AnalyticsService
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3Key
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
