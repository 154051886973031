import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'complete_onboarding',
    loadChildren: () =>
      import('./routes/complete-onboarding').then(
        (m) => m.CompleteOnboardingModule
      )
  },
  {
    path: '**',
    loadChildren: () =>
      import('./routes/request-invite').then((m) => m.RequestInviteModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
