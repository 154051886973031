import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyLogoComponent } from '../components/atoms/norby-logo/norby-logo.component';

@NgModule({
  declarations: [NorbyLogoComponent],
  imports: [CommonModule],
  exports: [NorbyLogoComponent]
})
export class NorbyLogoModule {}
