import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyArrowComponent } from '../components/atoms/norby-arrow/norby-arrow.component';

@NgModule({
  declarations: [NorbyArrowComponent],
  imports: [CommonModule],
  exports: [NorbyArrowComponent]
})
export class NorbyArrowModule {}
