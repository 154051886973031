<svg
  width="100%"
  viewBox="0 0 316 168"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M215.384 162.381L239.366 116.875H234.22C209.87 116.875 187.909 121.113 155.933 168H79.8499C100.8 130.601 126.529 81.687 117.891 81.687C105.946 81.687 75.8069 130.509 53.4784 168H0C17.4586 141.286 37.49 112.822 56.3268 88.3194C72.9584 66.7641 87.7522 49.1699 100.525 36.0894C124.599 11.4022 165.764 -14.7588 186.807 9.92835C205.092 31.3915 161.721 111.256 145.549 140.089C145.182 140.641 144.906 141.194 144.63 141.747C157.495 120.376 195.904 67.409 260.225 66.3957L265.921 66.672L283.196 33.9707L316 110.059L215.384 162.381Z"
    [ngStyle]="{ fill: color ? color : '#FFFFFF' }"
  />
</svg>
